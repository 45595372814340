import './AboutUs.scss'
import config from '../config.json'

const Content = () => {

  return (
    <div className="about-us">
      <div className="container">
        <div className="image"><img src={`${process.env.PUBLIC_URL}/top01.png`} alt=""/></div>
        <h2>おでわんMAPとは.</h2>
          <p>&#x1F43E; 愛犬と一緒に入れる飲食店<br />
            &#x1F43E; 愛犬と泊まれるホテル・宿<br />
            &#x1F43E; ドッグラン
          </p>
          <p>カテゴリの施設情報を地図上に表示しています（全国版）</p>
          <p>最大の特徴は実際におでかけしてきた飼い主様からの感想や写真で構成しているところ！ </p>
          <p>掲載している施設数は1400件超！<br />（2023年1月現在）</p>
          <p>2019年のおでかけわんこ部運営開始から毎日たくさんのおでかけ情報や写真を提供頂いております。</p>
          <p>おでわんMAPは全国のわんちゃんのおでかけの思い出がたっぷり詰まった愛情たっぷりのMAPです。</p>

        <h2>おでわんMAPでできること</h2>
          <p>01. 全国の愛犬と一緒に行けるスポットを地図で探せる</p>
          <div className="image mb3rem"><img src={`${process.env.PUBLIC_URL}/map01.png`} alt=""/></div>
          <p>02. アイコンをタップするとさらに詳細ページへ</p>
          <div className="image mb3rem"><img src={`${process.env.PUBLIC_URL}/map02.png`} alt=""/></div>
          <p>03. 探し方色々！<br />カテゴリで絞り込み検索や現在位置から近い順で探せる</p>
          <div className="image mb3rem"><img src={`${process.env.PUBLIC_URL}/map03.png`} alt=""/></div>

        <h2>おでわんMAPを使う方法は？</h2>
          <p>「おでわんMAP」はおでかけわんこ部メールマガジン（無料部員）への登録で無料で利用可能です。</p>
          <p>メールマガジン登録フォームは<a href="https://form.run/@odewan-member">こちら</a> </p>

        <h2>おでかけわんこ部とは</h2>
          <p>愛犬とのおでかけ情報を発信しているメディアです。</p>
          <p>「週末このカフェに行ってみようか?」<br/>「この景色を一緒に見にいこう!!」</p>
          <p>愛犬との楽しいおでかけのきっかけ作りサポートし「飼い主さんとわんこをHAPPYにする」ことが目的です。</p>

          <div className="image media"><img src={`${process.env.PUBLIC_URL}/odewan-media.png`} alt=""/></div>

          <p>webサイト: <a href="https://odekake-wanko-bu.com/">https://odekake-wanko-bu.com</a></p>
          <p>Instagram:  <a href="https://www.instagram.com/odewanbu/">@odewanbu</a></p>

        <h2>おでかけレポート募集中</h2>
          <p>あなたの大切な愛犬とのおでかけの思い出を全国の飼い主さまにもシェアしませんか？</p>
          <p>＼こんな情報を募集しております／<br />「愛犬と入れるカフェ」<br />「愛犬と泊まれる宿」<br />「ドッグラン」</p>

        <div className='form-container'>
          <a className="form" href={config.form_url}>情報提供フォーム</a>



         <h2>愛犬同伴OKの施設さまへ</h2>
          <p>おでわんMAPのリリースを記念して飲食店や宿、<br/>ドッグランなどの施設様あてに「DOG OK」シールを無料配布中！<br />（枚数限定）</p>

          <div className="image"><img src={`${process.env.PUBLIC_URL}/dogok-sticker.jpeg`} alt=""/></div>
          <p>「DOG OKシールがほしい」<br />「掲載してほしい・掲載情報を修正してほしい」<br />「愛犬同伴OKにしたいので相談したい」<br />などお問い合わせ先は<a href="https://odekake-wanko-bu.com/odewan-map/#dogok-sticker">こちら</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Content;
